<template>
  <v-container>
    <!-- toolbar -->
    <v-toolbar flat rounded color="primary" dark dense>
      <v-toolbar-title>Credits</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="addDialog = true"
        :disabled="loading"
        :loading="loading"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- table -->
    <v-data-table
      :headers="[
        { text: 'Email', value: 'coordinatorEmail' },
        { text: 'Amount', value: 'creditAmount' },
        { text: 'Org. Name', value: 'orgName' },
        { text: 'Issue Date', value: 'issueDate' },
        { text: 'Exp. Date', value: 'expirationDate' },
        { text: 'Invoice Applied To', value: 'invoiceAppliedTo' },
      ]"
      :items="credits"
      dense
      class="elevation-1 mt-6 cursorPointer"
      @click:row="editItem"
      :loading="loading"
      :item-class="itemClass"
    >
      <template v-slot:[`item.issueDate`]="{ item }">
        {{ formatDate(item.issueDate, "MM/DD/YYYY") }}
      </template>
      <template v-slot:[`item.expirationDate`]="{ item }">
        {{ formatDate(item.expirationDate, "MM/DD/YYYY") }}
        <span class="ml-1 caption">{{ daysUntilExpiration(item) }}</span>
      </template>
    </v-data-table>
    <!-- addDialog -->
    <v-dialog v-model="addDialog" width="500" persistent>
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Add Organization Credit
        </v-card-title>
        <v-card-text class="text--primary">
          <v-form ref="addForm" :disabled="loading">
            <v-text-field
              label="Organization Name *"
              v-model.trim="formData.orgName"
              :rules="[(v) => !!v || 'Required Field']"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Coordinator Email *"
              v-model.trim="formData.coordinatorEmail"
              :rules="[(v) => !!v || 'Required Field', rules.email]"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Package Returned *"
              v-model.trim="formData.packageReturned"
              :rules="[(v) => !!v || 'Required Field']"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Credit Amount *"
              v-model.trim="formData.creditAmount"
              :rules="[(v) => !!v || 'Required Field']"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Issue Date *"
              v-model.trim="formData.issueDate"
              :rules="[(v) => !!v || 'Required Field']"
              hint="MM/DD/YYYY"
            ></v-text-field>
            <v-text-field
              label="Expiration Date *"
              v-model.trim="formData.expirationDate"
              :rules="[(v) => !!v || 'Required Field']"
              hint="MM/DD/YYYY"
            ></v-text-field>
            <v-text-field
              label="Invoice Applied To *"
              v-model.trim="formData.invoiceAppliedTo"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="cancel"
            :disabled="loading"
            :loading="loading"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="addNewCredit"
            :disabled="loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- editDialog -->
    <v-dialog v-model="editDialog" width="500" persistent>
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Edit Organization Credit
        </v-card-title>
        <v-card-text class="text--primary">
          <v-form ref="editForm" :disabled="loading">
            <v-text-field
              label="Organization Name *"
              v-model.trim="itemToEdit.orgName"
              :rules="[(v) => !!v || 'Required Field']"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Coordinator Email *"
              v-model.trim="itemToEdit.coordinatorEmail"
              :rules="[(v) => !!v || 'Required Field', rules.email]"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Package Returned *"
              v-model.trim="itemToEdit.packageReturned"
              :rules="[(v) => !!v || 'Required Field']"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Credit Amount *"
              v-model.trim="itemToEdit.creditAmount"
              :rules="[(v) => !!v || 'Required Field']"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-text-field
              label="Issue Date *"
              v-model.trim="itemToEdit.issueDate"
              :rules="[(v) => !!v || 'Required Field']"
              hint="MM/DD/YYYY"
            ></v-text-field>
            <v-text-field
              label="Expiration Date *"
              v-model.trim="itemToEdit.expirationDate"
              :rules="[(v) => !!v || 'Required Field']"
              hint="MM/DD/YYYY"
            ></v-text-field>
            <v-text-field
              label="Invoice Applied To *"
              v-model.trim="itemToEdit.invoiceAppliedTo"
              :disabled="loading"
              :loading="loading"
            ></v-text-field>
            <v-alert text dense border="top" color="primary" class="py-0">
              <v-switch
                :loading="loading"
                :disabled="loading"
                dense
                v-model="itemToEdit.creditUsed"
                label="Credit Used"
              ></v-switch>
            </v-alert>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="cancel"
            :disabled="loading"
            :loading="loading"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="saveEdits"
            :disabled="loading"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import formRules from "@/mixins/formRules";
import { portalsApp } from "@/firebase";
import moment from "moment";
import formatDate from "@/mixins/formatDate";

export default {
  mixins: [formRules, formatDate],
  firestore: {
    credits: portalsApp.firestore().collection("credits"),
  },
  data: () => ({
    credits: [],
    loading: false,
    addDialog: false,
    editDialog: false,
    itemToEdit: {
      issueDate: "",
      expirationDate: "",
    },
    formData: {
      issueDate: "",
      expirationDate: "",
    },
  }),
  methods: {
    daysUntilExpiration({ expirationDate }) {
      let expDate = moment(expirationDate);
      if (!expDate._isAMomentObject) return;
      let diff = expDate.diff(moment(), "days");
      return `(${diff} days)`;
    },
    itemClass({ creditUsed, expirationDate }) {
      let classes = [];

      let expDate = moment(expirationDate);
      if (expDate._isAMomentObject) {
        let diff = expDate.diff(moment(), "days");
        if (diff < 0) classes.push("red lighten-2");
      }

      if (creditUsed) classes.push("grey lighten-2");

      return classes;
    },
    async editItem({ id }) {
      if (!id) return;
      this.loading = true;

      let doc = await portalsApp
        .firestore()
        .collection("credits")
        .doc(id)
        .get();
      if (!doc.exists) return (this.loading = false);

      this.itemToEdit = doc.data();

      // Convert YYYY-MM-DD to MM/DD/YYYY for display in input fields
      if (this.itemToEdit.issueDate) {
        this.itemToEdit.issueDate = moment(
          this.itemToEdit.issueDate,
          "YYYY-MM-DD"
        ).format("MM/DD/YYYY");
      }
      if (this.itemToEdit.expirationDate) {
        this.itemToEdit.expirationDate = moment(
          this.itemToEdit.expirationDate,
          "YYYY-MM-DD"
        ).format("MM/DD/YYYY");
      }

      this.editDialog = true;
      this.loading = false;
    },
    async saveEdits() {
      if (!this.$refs.editForm.validate())
        return this.$toast.error("Please check form for errors");

      this.loading = true;

      // Convert MM/DD/YYYY to YYYY-MM-DD before saving
      const normalizeDate = (dateStr) => {
        if (!dateStr) return ""; // Handle empty cases

        // Replace both '-' and '/' with a consistent '/'
        let normalized = dateStr.replace(/[-/]/g, "/");

        // Try parsing with common formats
        let parsed = moment(normalized, ["MM/DD/YYYY", "YYYY-MM-DD"], true);

        return parsed.format("YYYY-MM-DD");
      };

      // Apply normalization before saving
      this.itemToEdit.issueDate = normalizeDate(this.itemToEdit.issueDate);
      this.itemToEdit.expirationDate = normalizeDate(
        this.itemToEdit.expirationDate
      );

      await portalsApp
        .firestore()
        .collection("credits")
        .doc(this.itemToEdit.id)
        .update(this.itemToEdit);

      if (this.$refs.editForm) this.$refs.editForm.reset();
      this.editDialog = false;
      this.loading = false;
      this.$toast.success("Edits saved successfully");
    },
    async addNewCredit() {
      if (!this.$refs.addForm.validate())
        return this.$toast.error("Please check form for errors");

      this.loading = true;

      const normalizeDate = (dateStr) => {
        if (!dateStr) return "";
        let normalized = dateStr.replace(/[-/]/g, "/");
        let parsed = moment(normalized, ["MM/DD/YYYY", "YYYY-MM-DD"], true);
        return parsed.format("YYYY-MM-DD");
      };

      // Use formData instead of itemToEdit
      this.formData.issueDate = normalizeDate(this.formData.issueDate);
      this.formData.expirationDate = normalizeDate(
        this.formData.expirationDate
      );
      this.formData.creditUsed = false;

      await portalsApp
        .firestore()
        .collection("credits")
        .add(this.formData);

      if (this.$refs.addForm) this.$refs.addForm.reset();
      this.addDialog = false;
      this.loading = false;
      this.$toast.success("Credit added successfully");
    },
    cancel() {
      if (this.$refs.addForm) this.$refs.addForm.reset();
      if (this.$refs.editForm) this.$refs.editForm.reset();
      this.addDialog = false;
      this.editDialog = false;
      this.itemToEdit = {};
      this.loading = false;
    },
  },
};
</script>
